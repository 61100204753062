const { Box, CircularProgress } = require("@mui/material");

function CircularLoader() {
  return (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Add semi-transparent background
            zIndex: 9999 // Ensure the loader appears on top of other elements
        }}>
        <CircularProgress />
      </Box>
  );
}

export default CircularLoader;