import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './UserContext';
import { getCurrentUser, subscribeToUserUpdate } from 'src/services/auth-service';
import CircularLoader from 'src/components/CircularLoader';

export const UserLoader = ({ children }) => {
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        async function loadUser() {
            const user = await getCurrentUser();
            setUser(user);

            subscribeToUserUpdate((newUser) => {
                setUser(newUser);
            });
        }

        loadUser();
    }, [])

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {!user ? <CircularLoader /> : children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);