import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from 'src/components/layout/Layout';
import ProtectedRoute from 'src/components/ProtectedRoute';
import UnAuthenticated from 'src/pages/auth/unauthenticated.page';
import { subscribeToUserUpdate } from 'src/services/auth-service';
import './i18n';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { authRoutes } from './routes';
import { useTranslation } from 'react-i18next';
import CircularLoader from './components/CircularLoader';
import { UserLoader } from './components/loader/user/UserLoader';
import FavouritesLoader from './components/loader/favourites/FavouritesLoader';

function App() {
  const { t } = useTranslation();

  const [authenticated, setAuthenticated] = useState(false);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [rendering, setRendering] = useState(true);

  useEffect(() => {
    subscribeToUserUpdate(async (user) => {
      console.log('user changed');
      try {
        if (!user) {
          setAuthenticated(false);
          return;
        }

        setAuthenticated(true);

        if (user.deleted) {
          setAccountDeleted(true);
        }
      } finally {
        setRendering(false);
      }
    });
  }, [authenticated]);

  const authRoutesComponents = useMemo(() => {
    return authRoutes.map((routeItem) => {
      return (
        <Route
          path={routeItem.path}
          key={routeItem.path}
          element={

            <ProtectedRoute authenticated={authenticated} redirectPath={routeItem.redirectPath}>
              <UserLoader>
                <FavouritesLoader>
                  <Layout authenticated={authenticated}>{routeItem.content()}</Layout>
                </FavouritesLoader>
              </UserLoader>
            </ProtectedRoute>
          }
        />
      );
    });
  }, [authenticated]);

  if (rendering) {
    return <CircularLoader />;
  }

  if (accountDeleted) {
    return <>{t('accountDeletedMessage')}</>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnAuthenticated authenticated={authenticated} />} />

        {authRoutesComponents}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
